import React from 'react';
import { graphql } from 'gatsby';

import Container from '../components/Container';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Seo from '../components/Seo';

import '../fonts/ff-mark.css';
import Prose from '../components/Prose';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';

const templateFullWidthNoContainerOrSections = 'Full Width - No Container Or Section';

const PageTemplate = (props) => {
  const { wpPage } = props.data;
  const { title: postTitle, seo, content } = wpPage;

  const opengraphDescription = seo?.opengraphDescription ?? '';
  const metaDesc = (seo?.metaDesc ?? '').length > 0 ? seo?.metaDesc : opengraphDescription;

  const noContainer = (wpPage?.template?.templateName ?? '') === templateFullWidthNoContainerOrSections;

  return (
    <Layout>
      <Seo title={`${postTitle}`} description={metaDesc} />

      <Header graphic="3">{postTitle}</Header>

      <div className="page-content">
        {noContainer && <Prose className="relative pt-10 pb-[8rem]" content={content} />}

        {!noContainer && (
          <section className="bg-white text-sp-dark my-12 min-h-[48rem]">
            <Container>
              <Prose className="relative pt-10 pb-[8rem]" content={content} />
            </Container>
          </section>
        )}
      </div>

      <BuildRelationshipsSection />

      <Footer />
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    site {
      siteMetadata {
        menu {
          title
          uri
          external
        }
      }
    }
    wp {
      generalSettings {
        title
        description
      }
    }
    wpPage(id: { eq: $id }) {
      id
      slug
      title
      content
      seo {
        metaDesc
        opengraphDescription
      }
      template {
        templateName
      }
    }
  }
`;
